import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import tw from 'twin.macro'

import Layout from '../components/Layout'
import Container, { Col } from '../components/global/Container'
import Content, { HTMLContent } from '../components/global/Content'
import RightArrowIcon from '../graphics/icons/right-arrow-icon.svg'
import PageHeading from '../components/global/PageHeading'

export const BrochureTemplate = ({
  data,
  className,
  contentComponent,
  helmet,
}) => {
  const PageContent = contentComponent || Content

  return (
    <div className={className}>
      {helmet || ''}
      <PageHeading title={data.frontmatter.title} />
      <Container tw="py-12">
          {data.frontmatter.brochureImage &&
            <Col tw="xs:w-2/5">
              <a href={`/images/${data.frontmatter.brochureFile.relativePath}`}>
                <img 
                  src={ 
                    data.frontmatter.brochureImage.childImageSharp
                    ? data.frontmatter.brochureImage.childImageSharp.fluid.src
                    : data.frontmatter.brochureImage
                  }
                />
              </a>
            </Col>
            }
        <Col tw="flex-1">
          <PageContent className="content" content={data.html} />
          <a className="button" tw="py-3 w-40 justify-center pr-2 bg-techna-blue text-white rounded flex" href={`/images/${data.frontmatter.brochureFile.relativePath}`}>
            <RightArrowIcon tw="mr-1 transform rotate-90" /> Download 
          </a>
        </Col>
      </Container>
    </div>
  )
}

BrochureTemplate.propTypes = {
  contentComponent: PropTypes.func,
  className: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  helmet: PropTypes.object,
}

const BrochurePageComponent = ({ className, data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <BrochureTemplate
        className={className}
        contentComponent={HTMLContent}
        data={post}
        helmet={
          <Helmet titleTemplate="%s - Techna NDT">
            <title>{post.frontmatter.title}</title>
            <meta
              name="description"
              content={post.frontmatter.description}
            />
          </Helmet>
        }
      />
    </Layout>
  )
}

BrochurePageComponent.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
}

const BrochurePage = styled(BrochurePageComponent)`
  ${tw``}
  .button svg polygon {
    fill: #fff;
  }

`

export default BrochurePage

export const brochureQuery = graphql`
  query BrochurePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        brochureFile {
          relativePath
        }
        brochureImage {
          childImageSharp {
            fluid(maxWidth: 900, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
